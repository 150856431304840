import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// layouts
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import HorizontalLayout from '../layouts/Horizontal/';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// constants
import { LayoutTypes } from '../constants';

// hooks
import { useRedux } from '../hooks';

//Heteroidentificacao
const HeteroidentificacaoDocumento = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_documento'));
const HeteroidentificacaoFrente = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_foto_frente'));
const HeteroidentificacaoPerfil = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_foto_perfil'));
const HeteroidentificacaoVideo = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_video'));
const HeteroidentificacaoLogin = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_login'));
const HeteroidentificacaoLoginAvaliador = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_login_avaliador'));
const HeteroidentificacaoProtocolo = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_protocolo'));
const HeteroidentificacaoListaCandidatos = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_lista_candidatos'));
const HeteroidentificacaoListaCandidatosAvaliados = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_lista_candidatos_avaliados'));
const HeteroidentificacaoAvaliacaoCandidato = React.lazy(() => import('../pages/heteroidentificacao/heteroidentificacao_avaliacao_candidato'));


//prova_pratica_parana
const ListaCargos = React.lazy(() => import('../pages/prova_pratica_parana/lista_cargos'));
const UploadVideo = React.lazy(() => import('../pages/prova_pratica_parana/upload_video'));
const UploadPDF = React.lazy(() => import('../pages/prova_pratica_parana/upload_pdf'));
const ConfirmaUpload = React.lazy(() => import('../pages/prova_pratica_parana/confirma_upload'));
const ConfirmaUploadPDF = React.lazy(() => import('../pages/prova_pratica_parana/confirma_upload_pdf'));
const Protocolo = React.lazy(() => import('../pages/prova_pratica_parana/protocolo'));
const ListaCargosConsultaResultado = React.lazy(() => import('../pages/prova_pratica_parana/lista_cargos_consulta_resultado'));
const LoginConsultaResultado = React.lazy(() => import('../pages/prova_pratica_parana/LoginConsultaResultado'));
const ConsultaResultadoPraticaTitulos = React.lazy(() => import('../pages/prova_pratica_parana/consulta_resultado_pratica_titulos'));
const LoginConsultaResultadoTitulosAprovadosPosRec = React.lazy(() => import('../pages/prova_pratica_parana/LoginConsultaResultadoAprovadosAposRec'));

//avaliador do parana
const ListaCandidatosAvaliacao = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/lista_candidatos'));
const ListaCandidatosRecurso = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/lista_candidatos_recurso'));
const CandidatosAnalisados = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/candidatos_analisados'));
const AvaliacaoCandidatoReanalise = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/avaliacao_candidato_reanalise'));
const AvaliacaoCandidatoRecurso = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/avaliacao_candidato_recurso'));
const AvaliacaoCandidato = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/avaliacao_candidato'));
const LoginNormal = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/LoginNormal'));
const LoginPedagogo = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/LoginPedagogo'));
const LoginRecurso = React.lazy(() => import('../pages/avaliador_prova_pratica_parana/LoginRecurso'));
//Coordenacao
const CoordenacaoMenu = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/menu'));
const CoordenacaoLogin = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/LoginCoordenacao'));
const ResumoConsolidadoAvaliador = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/resumo_consolidado_avaliador'));
const Hidrometro = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/hidrometro'));
const Discrepancia = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/discrepancia'));
const Recursos = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/recursos'));
const VerCandidatoSimples = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/verCandidatoSimples'));
const ConsultaCandidato = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/consulta_candidatos'));
const GerenciaAvaliadores = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/GerenciaAvaliadores'));
const GerenciaAvaliadoresEditarAvaliador = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/GerenciaAvaliadoresEditar'));
const ConsultaNotas = React.lazy(() => import('../pages/coordenacao_prova_pratica_parana/consulta_nota'));
// lazy load all the views
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));

// dashboards
const DashBoard1 = React.lazy(() => import('../pages/dashboards/DashBoard1/'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const ChatApp = React.lazy(() => import('../pages/apps/Chat'));
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board'));
const TaskDetail = React.lazy(() => import('../pages/apps/Tasks/Detail'));
const Projects = React.lazy(() => import('../pages/apps/Projects'));
const List = React.lazy(() => import('../pages/apps/Contacts/List'));
const Profile = React.lazy(() => import('../pages/apps/Contacts/Profile'));

// extra pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Gallery = React.lazy(() => import('../pages/other/Gallery'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const ComingSoon = React.lazy(() => import('../pages/other/ComingSoon'));

// base ui
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
const TabsAccordions = React.lazy(() => import('../pages/uikit/TabsAccordions'));
const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvases'));
const Placeholders = React.lazy(() => import('../pages/uikit/Placeholders'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Images = React.lazy(() => import('../pages/uikit/Images'));
const Carousel = React.lazy(() => import('../pages/uikit/Carousel'));
const EmbedVedio = React.lazy(() => import('../pages/uikit/EmbedVideo'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const PopoversAndTooltips = React.lazy(() => import('../pages/uikit/PopoversAndTooltips'));
const GeneralUI = React.lazy(() => import('../pages/uikit/GeneralUI'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));

// widgets
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// extended ui
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSlider'));
const NestableList = React.lazy(() => import('../pages/uikit/NestableList'));
const SweetAlerts = React.lazy(() => import('../pages/uikit/SweetAlerts'));
const Tourpage = React.lazy(() => import('../pages/uikit/TourPage'));
const TreeViewExample = React.lazy(() => import('../pages/uikit/TreeView'));

// icons
const FeatherIcons = React.lazy(() => import('../pages/icons/FeatherIcons'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons'));
const Dripicons = React.lazy(() => import('../pages/icons/DripiIcons'));
const FontAwesomeIcons = React.lazy(() => import('../pages/icons/FontAwesomeIcons'));
const ThemifyIcons = React.lazy(() => import('../pages/icons/ThemifyIcons'));

// forms
const GeneralElements = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const Validation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));

// tables
const BasicTable = React.lazy(() => import('../pages/tables/BasicTable'));
const AdvancedTable = React.lazy(() => import('../pages/tables/AdvancedTable'));

// charts
const ApexChart = React.lazy(() => import('../pages/chart/ApexChart'));
const ChartJs = React.lazy(() => import('../pages/chart/ChartJs'));

// maps
const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

// lamding
const Landing = React.lazy(() => import('../pages/Landing'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: '/', element: <Root /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'auth',
          children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'register', element: <LoadComponent component={Register} /> },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: 'error-404',
          element: <LoadComponent component={Error404} />,
        },
        {
          path: 'error-500',
          element: <LoadComponent component={Error500} />,
        },
        {
          path: 'maintenance',
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: 'coming-soon',
          element: <LoadComponent component={ComingSoon} />,
        },
        {
          path: 'heteroidentificacao_documento',
          element: <LoadComponent component={HeteroidentificacaoDocumento} />,
        },
        {
          path: 'heteroidentificacao_frente',
          element: <LoadComponent component={HeteroidentificacaoFrente} />,
        },
        {
          path: 'heteroidentificacao_perfil',
          element: <LoadComponent component={HeteroidentificacaoPerfil} />,
        },
        {
          path: 'heteroidentificacao_video',
          element: <LoadComponent component={HeteroidentificacaoVideo} />,
        },
        {
          path: 'heteroidentificacao_login',
          element: <LoadComponent component={HeteroidentificacaoLogin} />,
        },
        {
          path: 'heteroidentificacao_login_avaliador',
          element: <LoadComponent component={HeteroidentificacaoLoginAvaliador} />,
        },
        {
          path: 'heteroidentificacao_protocolo',
          element: <LoadComponent component={HeteroidentificacaoProtocolo} />,
        },
        {
          path: 'heteroidentificacao_lista_candidatos_pendentes',
          element: <LoadComponent component={HeteroidentificacaoListaCandidatos} />,
        },
        {
          path: 'heteroidentificacao_avaliacao_candidato',
          element: <LoadComponent component={HeteroidentificacaoAvaliacaoCandidato} />,
        },
        {
          path: 'heteroidentificacao_lista_candidatos_avaliados',
          element: <LoadComponent component={HeteroidentificacaoListaCandidatosAvaliados} />,
        },
        {
          path: 'landing',
          element: <LoadComponent component={Landing} />,
        },
        {
          path: 'lista_candidatos_avaliacao',
          element: <LoadComponent component={ListaCandidatosAvaliacao} />,
        },
        {
          path: 'lista_candidatos_recurso',
          element: <LoadComponent component={ListaCandidatosRecurso} />,
        },
        {
          path: 'candidatos_analisados',
          element: <LoadComponent component={CandidatosAnalisados} />,
        },
   {
          path: 'avaliacao_candidato_reanalise',
          element: <LoadComponent component={AvaliacaoCandidatoReanalise} />,
        },
                  {
          path: 'avaliacao_candidato',
          element: <LoadComponent component={AvaliacaoCandidato} />,
        },
        {
          path: 'avaliacao_candidato_recurso',
          element: <LoadComponent component={AvaliacaoCandidatoRecurso} />,
        },
        {
          path: 'avaliador-login_normal',
          element: <LoadComponent component={LoginNormal} />,
        },
        {
          path: 'avaliador-login_pedagogo',
          element: <LoadComponent component={LoginPedagogo} />,
        },
        {
          path: 'avaliador-login_recurso',
          element: <LoadComponent component={LoginRecurso} />,
        },

        {
          path: 'coordenacao_menu',
          element: <LoadComponent component={CoordenacaoMenu} />,
        },
        {
          path: 'coordenacao_login_las_sjs_ksa',
          element: <LoadComponent component={CoordenacaoLogin} />,
        },
                  {
          path: 'coordenacao_resumo_consolidado_avaliador',
          element: <LoadComponent component={ResumoConsolidadoAvaliador} />,
        },
         {
          path: 'coordenacao_hidrometro',
          element: <LoadComponent component={Hidrometro} />,
        },
         {
          path: 'coordenacao_discrepancia',
          element: <LoadComponent component={Discrepancia} />,
        },
           {
          path: 'coordenacao_recursos',
          element: <LoadComponent component={Recursos} />,
        },
          {
          path: 'coordenacao_ver_candidato_simples',
          element: <LoadComponent component={VerCandidatoSimples} />,
        },
        {
          path: 'coordenacao_consulta_candidatos',
          element: <LoadComponent component={ConsultaCandidato} />,
        },
                 {
          path: 'coordenacao_gerencia_avaliadores',
          element: <LoadComponent component={GerenciaAvaliadores} />,
        },                 {
          path: 'coordenacao_gerencia_avaliadores_editar',
          element: <LoadComponent component={GerenciaAvaliadoresEditarAvaliador} />,
        },
        {
          path: 'coordenacao_teste_consulta_notas',
          element: <LoadComponent component={ConsultaNotas} />,
        },
          {
          path:'lista_cargos_consulta_resultado',
            element: <LoadComponent component={ListaCargosConsultaResultado} />,
        },
        {
          path:'login_consulta_resultado',
            element: <LoadComponent component={LoginConsultaResultado} />,
        },
        {
          path:'login_consulta_resultado_aprovados_pos_rec',
            element: <LoadComponent component={LoginConsultaResultadoTitulosAprovadosPosRec} />,
        },
        {
          path:'consulta_resultado_pratica_titulos',
            element: <LoadComponent component={ConsultaResultadoPraticaTitulos} />,
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute roles={'candidato'} component={Layout} />,
      children: [
        {
          path: 'dashboard',
          element: <LoadComponent component={DashBoard1} />,
        },
                  {
          path:'upload_pdf',
            element: <LoadComponent component={UploadPDF} />,
        },
        {
          path:'lista_cargos',
            element: <LoadComponent component={ListaCargos} />,
        },

        {
          path:'upload_video',
            element: <LoadComponent component={UploadVideo} />,
        },

        {
          path:'confirma_upload',
            element: <LoadComponent component={ConfirmaUpload} />,
        },
        {
          path:'confirma_upload_pdf',
            element: <LoadComponent component={ConfirmaUploadPDF} />,
        },
        {
          path:'protocolo',
            element: <LoadComponent component={Protocolo} />,
        },

        {
          path: 'apps',
          children: [
            {
              path: 'calendar',
              element: <LoadComponent component={CalendarApp} />,
            },
            {
              path: 'chat',
              element: <LoadComponent component={ChatApp} />,
            },
            {
              path: 'email/inbox',
              element: <LoadComponent component={Inbox} />,
            },
            {
              path: 'tasks/kanban',
              element: <LoadComponent component={Kanban} />,
            },
            {
              path: 'tasks/details',
              element: <LoadComponent component={TaskDetail} />,
            },
            {
              path: 'projects',
              element: <LoadComponent component={Projects} />,
            },
            {
              path: 'contacts/list',
              element: <LoadComponent component={List} />,
            },
            {
              path: 'contacts/profile',
              element: <LoadComponent component={Profile} />,
            },
          ],
        },
        {
          path: 'pages',
          children: [
            {
              path: 'starter',
              element: <LoadComponent component={Starter} />,
            },
            {
              path: 'pricing',
              element: <LoadComponent component={Pricing} />,
            },
            {
              path: 'timeline',
              element: <LoadComponent component={Timeline} />,
            },
            {
              path: 'invoice',
              element: <LoadComponent component={Invoice} />,
            },
            {
              path: 'faq',
              element: <LoadComponent component={FAQ} />,
            },
            {
              path: 'gallery',
              element: <LoadComponent component={Gallery} />,
            },
          ],
        },
        {
          path: 'base-ui',
          children: [
            {
              path: 'buttons',
              element: <LoadComponent component={Buttons} />,
            },
            {
              path: 'cards',
              element: <LoadComponent component={Cards} />,
            },
            {
              path: 'avatars',
              element: <LoadComponent component={Avatars} />,
            },
            {
              path: 'tabs-accordions',
              element: <LoadComponent component={TabsAccordions} />,
            },
            {
              path: 'notifications',
              element: <LoadComponent component={Notifications} />,
            },
            {
              path: 'modals',
              element: <LoadComponent component={Modals} />,
            },
            {
              path: 'progress',
              element: <LoadComponent component={Progress} />,
            },
            {
              path: 'offcanvas',
              element: <LoadComponent component={Offcanvases} />,
            },
            {
              path: 'placeholders',
              element: <LoadComponent component={Placeholders} />,
            },
            {
              path: 'spinners',
              element: <LoadComponent component={Spinners} />,
            },
            {
              path: 'images',
              element: <LoadComponent component={Images} />,
            },
            {
              path: 'carousel',
              element: <LoadComponent component={Carousel} />,
            },
            {
              path: 'embedvideo',
              element: <LoadComponent component={EmbedVedio} />,
            },
            {
              path: 'dropdowns',
              element: <LoadComponent component={Dropdowns} />,
            },
            {
              path: 'popovers-tooltips',
              element: <LoadComponent component={PopoversAndTooltips} />,
            },
            {
              path: 'general',
              element: <LoadComponent component={GeneralUI} />,
            },
            {
              path: 'typography',
              element: <LoadComponent component={Typography} />,
            },
            {
              path: 'grid',
              element: <LoadComponent component={Grid} />,
            },
          ],
        },
        {
          path: 'widgets',
          element: <LoadComponent component={Widgets} />,
        },
        {
          path: 'extended-ui',
          children: [
            {
              path: 'nestable',
              element: <LoadComponent component={NestableList} />,
            },
            {
              path: 'rangesliders',
              element: <LoadComponent component={RangeSliders} />,
            },
            {
              path: 'sweet-alert',
              element: <LoadComponent component={SweetAlerts} />,
            },
            {
              path: 'tour',
              element: <LoadComponent component={Tourpage} />,
            },
            {
              path: 'treeview',
              element: <LoadComponent component={TreeViewExample} />,
            },
          ],
        },
        {
          path: 'icons',
          children: [
            {
              path: 'feather',
              element: <LoadComponent component={FeatherIcons} />,
            },
            {
              path: 'mdi',
              element: <LoadComponent component={MDIIcons} />,
            },
            {
              path: 'dripicons',
              element: <LoadComponent component={Dripicons} />,
            },
            {
              path: 'font-awesome',
              element: <LoadComponent component={FontAwesomeIcons} />,
            },
            {
              path: 'themify',
              element: <LoadComponent component={ThemifyIcons} />,
            },
          ],
        },
        {
          path: 'forms',
          children: [
            {
              path: 'basic',
              element: <LoadComponent component={GeneralElements} />,
            },
            {
              path: 'advanced',
              element: <LoadComponent component={FormAdvanced} />,
            },
            {
              path: 'validation',
              element: <LoadComponent component={Validation} />,
            },
            {
              path: 'wizard',
              element: <LoadComponent component={FormWizard} />,
            },
            {
              path: 'upload',
              element: <LoadComponent component={FileUpload} />,
            },
            {
              path: 'editors',
              element: <LoadComponent component={Editors} />,
            },
          ],
        },
        {
          path: 'tables',
          children: [
            {
              path: 'basic',
              element: <LoadComponent component={BasicTable} />,
            },
            {
              path: 'advanced',
              element: <LoadComponent component={AdvancedTable} />,
            },
          ],
        },
        {
          path: 'charts',
          children: [
            {
              path: 'apex',
              element: <LoadComponent component={ApexChart} />,
            },
            {
              path: 'chartjs',
              element: <LoadComponent component={ChartJs} />,
            },
          ],
        },
        {
          path: 'maps',
          children: [
            {
              path: 'google',
              element: <LoadComponent component={GoogleMaps} />,
            },
            {
              path: 'vector',
              element: <LoadComponent component={VectorMaps} />,
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
